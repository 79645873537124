;
// our overrides
@import './LsRetail/_LsColors.scss'; // our theme colors
@import './LsRetail/LsMsCssOverrides'; // our overrides of microsoft classes that don't fit in a Views/... scss file

// bootstrap
@import 'bootstrap/scss/bootstrap';

// third party scss
@import '@msdyn365-commerce-modules/fabrikam-design-kit/dist/lib/modules/fabrikam/styles/fabrikam.theme.scss';

// our scss
@import 'Views/Ingredients';
@import 'Views/ProductModifierGroups';
@import 'Views/CartLines';
@import 'Views/LSFirstAlert';
@import 'Views/DealModifierGroups';
@import 'Views/RecipeView';
@import 'Views/PredefinedComments';

// fix table going out of view and fix microsoft elements not sizing properly
@media (max-width: 1350px) {
    .ms-buybox {
        flex-flow: column;
    }
    .msc-carousel__inner {
        overflow: hidden !important;
    }
    .product-add-to-cart {
        .buttons {
            max-width: 300px;
        }
    }
    .RecipeView {
        flex-direction: row;
        flex-wrap: wrap;
        & > * {
            margin-left: 20px;
        }
    }
    .ProductModifierGroups-Container {
        flex-basis: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .ProductModifierGroups-Header {
        flex-basis: 100%;
    }
    .ProductModifierGroups-TableContainer {
        margin-right: 5rem;
    }
}
