.PredefinedComments-checkboxes-container {
    display: flex;
    flex-direction: column;
    user-select: none;
    label {
        display: flex;
        cursor: pointer;
        position: relative;
        overflow: hidden;

        input {
            position: absolute;
            left: -9999px;
            &:checked + span {
                background-color: mix(#fff, theme-color('primary'), 84%);
                &:before {
                    box-shadow: inset 0 0 0 1em theme-color('primary');
                    content: '\2714';
                }
            }
            &:disabled + span {
                cursor: default;
                &:hover {
                    background-color: transparent;
                }
                &:before {
                    box-shadow: inset 0 0 0 0.125em #c9c9c9;
                }
            }
        }

        span {
            display: flex;
            align-items: center;
            padding: 0.375em 0.75em 0.375em 0.375em;
            border-radius: 10px;
            transition: 0.25s ease;
            &:hover {
                background-color: mix(#fff, theme-color('primary'), 84%);
            }
            &:before {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                content: '';
                background-color: #fff;
                color: white;
                width: 1.5em;
                height: 1.5em;
                border-radius: 25%;
                margin-right: 0.375em;
                transition: 0.25s ease;
                box-shadow: inset 0 0 0 0.125em theme-color('primary');
            }
        }
    }
}
