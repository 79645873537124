.pmg-table {
    max-width: 400px;
    border-collapse: collapse;
}

.pmg-thead {
    display: table-header-group;
    text-align: left;
    border-bottom: 1px solid black;
    th:nth-of-type(1) {
        max-width: 150px;
        min-width: 150px;
    }
    th:nth-of-type(2) {
        max-width: 75px;
        min-width: 75px;
    }
    th:nth-of-type(3) {
        max-width: 175px;
        min-width: 175px;
    }
}

.pmg-tr {
    border-bottom: 1px solid black;
    td:nth-of-type(1) {
        max-width: 150px;
        min-width: 150px;
        vertical-align: middle;
    }
    td:nth-of-type(2) {
        max-width: 75px;
        min-width: 75px;
        vertical-align: middle;
    }
    td:nth-of-type(3) {
        max-width: 175px;
        min-width: 175px;
    }
}

.pmg-td {
    border-right: 1px solid black;
    overflow: hidden;
}

.pmg-quantityDisplay {
    display: flex;
    justify-content: space-around;
    span {
        text-align: center;
        flex-grow: 1;
        align-self: center;
    }
}

.pmg-qtybtn {
    width: 50px;
}

.productModifierBuyBoxMandatorySelection {
    white-space: pre;
}
