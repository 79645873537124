.dealModifierGroups-radio-container {
    display: flex;
    flex-direction: column;
    user-select: none;
    label {
        display: flex;
        cursor: pointer;
        position: relative;
        overflow: hidden;

        input {
            // hide the original ugly input circle way to the left
            position: absolute;
            left: -9999px;
            // style the span immediately after each checked input
            &:checked + span {
                background-color: mix(#fff, theme-color('primary'), 84%);
                &:before {
                    box-shadow: inset 0 0 0 0.4375em theme-color('primary');
                }
            }
        }
        span {
            display: flex;
            align-items: center;
            padding: 0.375em 0.75em 0.375em 0.375em;
            border-radius: 99em;
            transition: 0.25s ease;
            &:hover {
                background-color: mix(#fff, theme-color('primary'), 84%);
            }
            &:before {
                display: flex;
                flex-shrink: 0;
                content: '';
                background-color: #fff;
                width: 1.5em;
                height: 1.5em;
                border-radius: 50%;
                margin-right: 0.375em;
                transition: 0.25s ease;
                box-shadow: inset 0 0 0 0.125em theme-color('primary');
            }
        }
    }
}
