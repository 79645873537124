.lsfirst-alert-line {
    @extend .msc-alert__line;
}

.lsfirst-alert-header {
    @extend .msc-alert__header;
}

.lsfirst-exclamation-triangle {
    @extend .msi-exclamation-triangle;
}
